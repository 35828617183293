import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface Locale {
  lang: string;
  data: Object;
}

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  private langIds: any = [];

  private browserLangMap = new Map([
    ['zh-TW', 'zh-Hant'],
    ['zh-HK', 'zh-Hant'],
    ['zh-CN', 'zh-Hans'],
  ]);

  constructor(private translate: TranslateService) {
    // add new langIds to the list
    this.translate.addLangs(['zh-Hans']);

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('zh-Hans');
  }

  public loadTranslations(...args: Locale[]): void {
    const locales = [...args];

    locales.forEach(locale => {
      // use setTranslation() with the third argument set to true
      // to append translations instead of replacing them
      this.translate.setTranslation(locale.lang, locale.data, true);

      this.langIds.push(locale.lang);
    });

    // add new languages to the list
    this.translate.addLangs(this.langIds);
  }

  setLanguage(lang) {
    if (lang) {
      this.translate.use(this.translate.getDefaultLang());
      this.translate.use(lang);
      localStorage.setItem('v6_language', lang);
    }
  }

  public getSelectedLanguage(): string {
    const browserLang = this.translate.getBrowserCultureLang();
    return localStorage.getItem('v6_language') || this.browserLangMap.get(browserLang) || this.translate.getDefaultLang();
  }
}
