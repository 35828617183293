import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

// Core
import { ResponseModel } from '@_core/models/response';

// Config
import { API } from '@quantum-theme/core/_config/api/api';

@Injectable()
export class RolePermissionService {

  constructor(
    private http: HttpClient,
  ) { }

  // getRolePermission(): Observable<ResponseModel> {
  //   return this.http.get<ResponseModel>(API + '/role-permission');
  // }

  getRolePermissions() {
    const rolePermissions = [
      {
        role: 'EHS004', // admin
        // permissions: [
        //   'ehs/dashboard',
        //   'ehs/training/lesson',
        //   'ehs/training/instructor',
        //   'ehs/training/class',
        //   'ehs/training/calendar',
        //   'ehs/training/record',
        //   'ehs/training/exemption',
        //   'ehs/training/certificate'
        //   'editRecord'
        // ]
      },
      {
        role: 'EHS003', // user
        // permissions: [
        //   'ehs/dashboard',
        //   'ehs/training/calendar',
        // ]
      }
    ];
    return of(rolePermissions);
  }
}
