import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '@quantum-theme/core/_config/api/api';
import { Observable } from 'rxjs';
import { ResponseModel } from '@_core/models/response';
import { QueryUtilsModel } from '@_core/models/logic-custom-query/query-utils.model';
import { QueryCriteria } from '@_core/models/logic-custom-query/query-criteria.model';

const employeeAPI = API.employee;
const baseUrl = API.baseUrl;
const projectReportAPI = API.projectReport;

@Injectable()
export class EmployeeService {

  constructor(
    private http: HttpClient,
  ) { }

  // 拿出指定Employee
  getEmployee(id: string | number): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(API.employee + '/' + id);
  }

  // 拿出登入者部門與登入者部門之子部門的 Employee List
  getEmployeeList(queryUtil: QueryUtilsModel): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(employeeAPI + '/list', queryUtil);
  }

  // 拿出指定(或不指定就全部)部門與指定(或不指定就全部)部門之子部門的 Employee List，人員部門不合併
  getDivisionalList(queryUtil: QueryUtilsModel): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(employeeAPI + '/divisionalList', queryUtil);
  }

  // 拿出指定(或不指定就全部)部門與指定(或不指定就全部)部門之子部門的 Employee List，人員部門合併
  getAutocompleteList(queryUtil: QueryUtilsModel): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(employeeAPI + '/autocompletelist', queryUtil);
  }

  getTopOrgIdList(queryUtil: QueryUtilsModel): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(employeeAPI + '/parallelSignoff/divisionalList', queryUtil);
  }

  getTopOuidList(queryUtil: QueryUtilsModel): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(employeeAPI + '/topOuidList', queryUtil);
  }

  // 获取运作总监下拉
  getOperateDirector(): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(baseUrl + '/qpeInspection/getAllOperateDirector' );
  }

  // messageGroupList(): Observable<ResponseModel> {
  //   return this.http.get<ResponseModel>(baseUrl + '/messageGroup/groupName/certificationEvaluationEmail' );
  // }

  getEmployeeByOrgGroup(org?: any[], group?: any[]) {
    const query = {
      org: [],
      group: []
    };
    if (org && org.length > 0) {
      query.org = org;
    } else {
      delete query.org;
    }
    if (group && group.length > 0) {
      query.group = group;
    } else {
      delete query.group;
    }
    return this.http.post<ResponseModel>(baseUrl + '/message/loadUsers', query);
  }

  // 获取认证测评邮件通知对象
  getMessageGroupList(groupCode?: string, employeeName?: string){
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: -1,
        pageSize: -1
      },
      // queryOrderBies: [
      //   {
      //     columnName: 'code',
      //     orderType: 'asc'
      //   }
      // ]

      // queryCriterias: []
    };
    queryUtils['params'] = {
      groupCode: groupCode ? groupCode : null,
      employeeName: employeeName ? employeeName : null
    };
    return this.http.post<ResponseModel>(baseUrl + '/messageGroup/groupName/list', queryUtils);
  }

  private genQueryUtils(queryCriterias: QueryCriteria[]): QueryUtilsModel {
    return {
      pagingTool: {
        currentPage: 1,
        pageSize: 20,
      },
      queryCriterias: queryCriterias,
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'desc'
        }
      ]
    };
  }

  private queryUtilsTitlesAll(queryCriterias: QueryCriteria[]): QueryUtilsModel {
    return {
      pagingTool: {
        currentPage: -1,
        pageSize: -1,
      },
      queryCriterias: queryCriterias,
      queryOrderBies: []
    };
  }

  private genQueryNoOrder(queryCriterias: QueryCriteria[]): QueryUtilsModel {
    return {
      pagingTool: {
        currentPage: 1,
        pageSize: 20,
      },
      queryCriterias: queryCriterias,
      queryOrderBies: []
    };
  }

  queryEmployee(query?: string, orgId?: number): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils([
      ...(orgId ? [new QueryCriteria('org_ids', orgId.toString(), 'like')] : []),
      // ...(query ? [new QueryCriteria('full_name', query, 'like', 'or')] : []),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : [])
    ]);
    return this.http.post<ResponseModel>(employeeAPI + '/autocompletelist/titles', queryUtils);
  }

  queryEmployeeSignTable(queryUtils): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(employeeAPI + '/autocompletelist/titles', queryUtils);
  }

  // TODO: Entry '/listMin' returns a minified employee DTOs 'EmployeeMin' with special columns needs to be unified.
  queryEmployeeMin(query?: string): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 20,
      },
      queryCriterias: [
        ...(query ? [new QueryCriteria('employee_name', query, 'like', 'and')] : []),
        ...(query ? [new QueryCriteria('employee_code', query, 'like', 'or')] : []),
      ],
      queryOrderBies: [
        {
          columnName: 'employee_code',
          orderType: 'desc'
        }
      ]
    };
    return this.http.post<ResponseModel>(employeeAPI + '/listMin', queryUtils);
  }

  queryFullTime(query?: string): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils([
      new QueryCriteria('contractor', '0', '=', 'and', true),
      // ...(query ? [new QueryCriteria('full_name', query, 'like', 'or')] : []),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : [])
    ]);
    return this.http.post<ResponseModel>(employeeAPI + '/list', queryUtils);
  }

  queryContractor(query?: string): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils([
      new QueryCriteria('contractor', '1', '=', 'and', true),
      // ...(query ? [new QueryCriteria('full_name', query, 'like', 'or')] : []),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : [])
    ]);
    return this.http.post<ResponseModel>(employeeAPI + '/list', queryUtils);
  }

  queryDivisional(query?: string, orgId?: number): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils([
      ...(orgId ? [new QueryCriteria('top_org_id', orgId.toString(), '=')] : []),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : [])
    ]);
    return this.http.post<ResponseModel>(employeeAPI + '/divisionalList', queryUtils);
  }

  queryAutocompleteList(query?: string, orgId?: number, title?: string, is_business_line?: string, ischeck?): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils([
      ...(orgId ? [new QueryCriteria('org_ids', orgId.toString(), '=')] : []),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(title ? [new QueryCriteria('titles', title, '=')] : []),
      ...(is_business_line ? [new QueryCriteria('is_business_line', is_business_line, '=')] : [])
    ]);
    if (ischeck) {
        queryUtils['params'] = {
          needChecked: ischeck,
        };
    }
    return this.http.post<ResponseModel>(employeeAPI + '/autocompletelist', queryUtils);
  }


  queryReportingPersonList(query?: string, businessLine?: string, org_operation?:string, org_area?:string, org_program?:string,  ischeck?): Observable<ResponseModel> {
    // debugger;
    const queryUtils: QueryUtilsModel = this.genQueryUtils([
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(businessLine ? [new QueryCriteria('org_business_line', businessLine, '=')] : []),
      ...(org_operation ? [new QueryCriteria('org_operation', org_operation, '=')] : []),
      ...(org_area ? [new QueryCriteria('org_area', org_area, '=')] : []),
      ...(org_program ? [new QueryCriteria('org_program', org_program, '=')] : []),
    ]);
    if (ischeck) {
        queryUtils['params'] = {
          needChecked: ischeck,
        };
    }
    return this.http.post<ResponseModel>(employeeAPI + '/autocompletelist', queryUtils);
  }

  queryVerifiedList(query?: string, orgId?: number, title?: string): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils([
      ...(orgId ? [new QueryCriteria('org_ids', orgId.toString(), '=')] : []),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(title ? [new QueryCriteria('titles', title, '=')] : []),
    ]);
    queryUtils['queryCriterias'].push({
      condition: "!=",
      connection: "and",
      isValueADigital: false,
      key: "emp.employee_type",
      value: "_SYS_WH_10_7",
    });
    return this.http.post<ResponseModel>(employeeAPI + '/autocompleteinfolist/titles', queryUtils);
  }

  queryAutocompleteListTitles(query?: string, orgId?: number, title?: string, is_business_line?: string, noOrder?: boolean,  checkAll?: boolean): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this[checkAll ? 'queryUtilsTitlesAll' : (noOrder ? 'genQueryNoOrder' : 'genQueryUtils')]([
      ...(orgId ? [new QueryCriteria('org_ids', orgId.toString(), '=')] : []),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(title ? [new QueryCriteria('titles', title, '=')] : []),
      ...(is_business_line ? [new QueryCriteria('is_business_line', is_business_line, '=')] : [])
    ]);
    return this.http.post<ResponseModel>(employeeAPI + '/autocompletelist/titles', queryUtils);
  }

  autocompleteListFirstReview(query?: string, orgId?: number, title?: string, is_business_line?: string): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils([
      ...(orgId ? [new QueryCriteria('org_ids', orgId.toString(), '=')] : []),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(title ? [new QueryCriteria('titles', title, '=')] : []),
      ...(is_business_line ? [new QueryCriteria('is_business_line', is_business_line, '=')] : [])
    ]);
    return this.http.post<ResponseModel>(employeeAPI + '/autocompletelist/deviation', queryUtils);
  }

  queryAutocompleteListNew(query?: string, orgId?: number, title?: string, is_business_line?: string): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils([
      ...(orgId ? [new QueryCriteria('org_ids', orgId.toString(), '=')] : []),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(title ? [new QueryCriteria('titles', title, '=')] : []),
      ...(is_business_line ? [new QueryCriteria('is_business_line', is_business_line, '=')] : [])
    ]);
    return this.http.post<ResponseModel>(employeeAPI + '/autocompletelist/titles', queryUtils);
  }

  // 轉派使用
  queryAllOrgsEmployee(query?: string, orgId?: number): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils([
      ...(orgId ? [new QueryCriteria('org_ids', orgId.toString(), 'like')] : []),
      // ...(query ? [new QueryCriteria('full_name', query, 'like', 'or')] : []),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : [])
    ]);
    return this.http.post<ResponseModel>(employeeAPI + '/allOrgsEmployeeList', queryUtils);
  }

  queryAllOrgsContact(query?: string, orgId?: number): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils([
      ...(orgId ? [new QueryCriteria('t.org_id', orgId.toString(), '=')] : []),
      // ...(query ? [new QueryCriteria('full_name', query, 'like', 'or')] : []),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : [])
    ]);
    return this.http.post<ResponseModel>(employeeAPI + '/allOrgsContactList', queryUtils);
  }
  
  // 获取QPE查阅人list
  qpeRevewerList(query?: string, serviceType?: string): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryNoOrder([
      ...(serviceType ? [new QueryCriteria('serviceType', serviceType, '=')] : []),
      ...(query ? [new QueryCriteria('emp_info.last_name', query, 'like', 'and')] : [])
    ]);
    return this.http.post<ResponseModel>(employeeAPI + '/qpeGroup', queryUtils);
  }

  // 获取合规文件查阅人list
  docReviewerList(query?: string): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryNoOrder([
      ...(query ? [new QueryCriteria('last_name', query, 'like', 'and')] : [])
    ]);
    return this.http.post<ResponseModel>(projectReportAPI + '/viewerList', queryUtils);
  }

  getCorpChildren(orgId: number[]): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(baseUrl + '/corporates/allChildren', orgId);
  }

  // 获取下拉，项目经理排在前面
  getListOrderManagerFirst(orgId): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryNoOrder([
      ...(orgId ? [new QueryCriteria('org_ids', orgId, '=')] : []),
    ]);
    return this.http.post<ResponseModel>(baseUrl + '/corporates/getPersonnel', queryUtils);
  }

  // 判断是否是.项目经理、一线管理员（职称）、运作经理（vp-1、VP-2）
  havePermission(employeeId: number, orgId: number): Observable<ResponseModel>{
    return this.http.get<ResponseModel>(baseUrl+ `/inspectionTasks/employeeId/${employeeId}/${orgId}`);
  }

  private inspectionQueryUtils(queryCriterias: QueryCriteria[]): QueryUtilsModel {
    return {
      pagingTool: {
        currentPage: 1,
        pageSize: 20,
      },
      queryCriterias: queryCriterias,
      // queryOrderBies: []
    };
  }

  // 检查人下拉里，要根据组织架构筛选显示
  getInspectionEmployeeList(query?: string,businessLine?: string, org_operation?:string, org_area?:string, org_program?:string ): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.inspectionQueryUtils([
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(businessLine ? [new QueryCriteria('org_business_line', businessLine, '=')] : []),
      ...(org_operation ? [new QueryCriteria('org_operation', org_operation, '=')] : []),
      ...(org_area ? [new QueryCriteria('org_area', org_area, '=')] : []),
      ...(org_program ? [new QueryCriteria('org_program', org_program, '=')] : []),
    ])
    return this.http.post<ResponseModel>(employeeAPI + '/inspection/list', queryUtils);
  }

}
