import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MatPaginatorI18nService extends MatPaginatorIntl {
  constructor(
    private translate: TranslateService
  ) {
    super();
    this.translateLabels();
    this.translate.onLangChange.subscribe(() => {
      this.translateLabels();
    });
  }
  getRangeLabel = (page, pageSize, length) => {
    if (length === 0 || pageSize === 0) {
      return '';
    }
    const totalPage = Math.ceil(length / pageSize);
    const infoText = this.translate.instant('DX.PAGER.INFOTEXT');
    return infoText
      .replace('{0}', page + 1)
      .replace('{1}', totalPage)
      .replace('{2}', length);
  }

  translateLabels() {
    this.itemsPerPageLabel = this.translate.instant('MAT.PAGINATOR.ITEMS_PER_PAGE');
    this.nextPageLabel = this.translate.instant('MAT.PAGINATOR.NEXT');
    this.previousPageLabel = this.translate.instant('MAT.PAGINATOR.PREVIOUS');
    this.firstPageLabel = this.translate.instant('MAT.PAGINATOR.FIRST');
    this.lastPageLabel = this.translate.instant('MAT.PAGINATOR.LAST');
    this.changes.next();
  }
}
