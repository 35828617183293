import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '@quantum-theme/core/_config/api/api';
import { Observable, of } from 'rxjs';
import { ResponseModel } from '@_core/models/response';
import { QueryUtilsModel } from '@_core/models/logic-custom-query/query-utils.model';
import { QueryCriteria } from '@_core/models/logic-custom-query/query-criteria.model';
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

const baseUrlAPI = API.baseUrl;

@Injectable()
export class RegulationService {
  constructor(
    private http: HttpClient,
  ) { }

  getRegulationList(): Observable<ResponseModel> {
    // const queryUtils: QueryUtilsModel = this.genQueryUtils();
    // return this.http.post<ResponseModel>(baseUrlAPI + '/jobs/list', queryUtils);
    return of({ MSG: "", STATUS: 0, TOTAL_COUNT: 3, DATA: [{ id: "30", name: "ABCDE", regulation_type: ["_SYS_AR_S", "_SYS_AT_5", "_SYS_BI_1"], issued_by: "_SYS_AS_3", issued_date: "2001-11-14T05:00:00.000Z", effective_date: "2002-05-01T05:00:00.000Z", regulation_level: "", url: "", attachment: "7,985,986", revision_note: "Add revision Notes", status: "_SYS_A9_1", sequence: "24", comment: null, updated_by: null, updated_date: null, sRegulation_type: "_SYS_AR_S,_SYS_AT_5,_SYS_BI_1", org_ids: null, editable: null, regulation_industry: null, regulationSetting: null, url_count: null }, { id: "33", name: "AB123", regulation_type: ["_SYS_AR_E", "_SYS_AT_5", "_SYS_BI_3"], issued_by: "_SYS_AS_5", issued_date: "2008-06-25T04:00:00.000Z", effective_date: "2008-08-01T04:00:00.000Z", regulation_level: null, url: null, attachment: "", revision_note: null, status: "_SYS_A9_1", sequence: "2", comment: null, updated_by: null, updated_date: null, sRegulation_type: "_SYS_AR_E,_SYS_AT_5,_SYS_BI_3", org_ids: null, editable: null, regulation_industry: null, regulationSetting: null, url_count: null }, { id: "34", name: "123DE", regulation_type: ["_SYS_AR_E", "_SYS_AT_6", "_SYS_BI_1"], issued_by: "_SYS_AS_6", issued_date: "2010-10-20T04:00:00.000Z", effective_date: "2010-10-20T04:00:00.000Z", regulation_level: null, url: null, attachment: "", revision_note: null, status: "_SYS_A9_1", sequence: "3", comment: null, updated_by: null, updated_date: null, sRegulation_type: "_SYS_AR_E,_SYS_AT_6,_SYS_BI_1", org_ids: null, editable: null, regulation_industry: null, regulationSetting: null, url_count: null }] });
  }

  private genQueryUtils(): QueryUtilsModel {
    return {
      pagingTool: {
        currentPage: 1,
        pageSize: 1000,
      },
    };
  }
}
