import { Observable, Subject, from, throwError, of, BehaviorSubject } from 'rxjs';
import { map, catchError, tap, switchMap, delay } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'ngx-auth';

import { TokenStorage } from './token-storage.service';
import { UtilsService } from '@_core/services/utils.service';
import { AccessData } from '../models/access-data';
import { Credential } from '../models/credential';
import { API } from '@quantum-theme/core/_config/api/api';
import { TranslationService } from '@quantum-theme/core/_base/layout/services/translation.service';

import { Store, select } from '@ngrx/store';
import * as fromAuth from '../reducers';

import { SystemCodeService } from '@_core/services/system-code.service';
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';
@Injectable()
export class AuthenticationService implements AuthService {
  API_URL = 'api';
  API_ENDPOINT_LOGIN = '/permissions/login';
  API_ENDPOINT_REFRESH = '/refresh';
  API_ENDPOINT_REGISTER = '/register';

  public onCredentialUpdated$: Subject<AccessData>;
  trainingClassificationList$: BehaviorSubject<any[]> = new BehaviorSubject([]);

  constructor(
    private http: HttpClient,
    private tokenStorage: TokenStorage,
    private store: Store<fromAuth.State>,
    private util: UtilsService,
    private translationService: TranslationService,
    private systemCodeService: SystemCodeService,
  ) {
    this.onCredentialUpdated$ = new Subject();
    // if (location.href.indexOf('/screen-dashboard/') === -1) {
    //   const { 
    //     trainingClassification,
    //   } = SystemCodeMap;
    //   this.systemCodeService.loadCodes([
    //     trainingClassification,
    //   ]).subscribe(data => {
    //     this.trainingClassificationList$.next(data[trainingClassification].childCodes);
    //   });
    // }
  }

	/**
	 * Check, if user already authorized.
	 * @description Should return Observable with true or false values
	 * @returns {Observable<boolean>}
	 * @memberOf AuthService
	 */
  public isAuthorized(): Observable<boolean> {
    return this.tokenStorage.getAccessToken().pipe(map(token => !!token));
  }

	/**
	 * Get access token
	 * @description Should return access token in Observable from e.g. localStorage
	 * @returns {Observable<string>}
	 */
  public getAccessToken(): Observable<string> {
    return this.tokenStorage.getAccessToken();
  }

	/**
	 * Get user roles
	 * @returns {Observable<string[]>}
	 */
  public getUserRoles(): Observable<string[]> {
    return this.store.pipe(
      select(fromAuth.selectAuthState),
      map(state => state.userRoles)
    );
  }

	/**
	 * Get user roles
	 * @returns {Observable<any[]>}
	 */
  public getUserDetailRoles(): Observable<any[]> {
    return this.store.pipe(
      select(fromAuth.selectAuthState),
      map(state => state.userDetailRoles)
    );
  }

  /**
   * Get user name
   * @returns {Observable<string>}
   */
  public getUserName(): Observable<string> {
    return this.store.pipe(
      select(fromAuth.selectAuthState),
      map(state => state.userName)
    );
  }

  /**
   * Get user id
   */
  public getUserId(): Observable<number> {
    return this.store.pipe(
      select(fromAuth.selectAuthState),
      map(state => state.userId)
    );
  }

  /**
  * Get user orgs
  */
   public getUserOrgs(): Observable<string> {
     return this.store.pipe(
       select(fromAuth.selectAuthState),
       map(state => state.userOrgs)
     );
   }

  /**
   * Get access modules
   * @returns {Observable<string[]>}
   */
  public getAccessModules(): Observable<string[]> {
    return this.store.pipe(
      select(fromAuth.selectAuthState),
      map(state => state.permissionList)
    );
  }

  public getUserRoleHasCompany(): Observable<string> {
    return this.store.pipe(
      select(fromAuth.selectAuthState),
      map(state => state.roleHasCompanyLevel)
    );
  }

  public getUserPermissions(): Observable<any> {
    // return this.http.get(API.menus + '/list');
    return this.store.pipe(
      select(fromAuth.getAuthToken),
      switchMap(token => {
        if (token) {
          return this.http.get(API.menus + '/list', { headers: { token } });
        } else {
          return of('');
        }

      }),
    );
  }

	/**
	 * Get user user detail from token
	 * @returns {Observable<any>}
	 */
  public getUserDetailFromToken(): Observable<any> {
    return this.store.pipe(
      select(fromAuth.getAuthToken),
      switchMap(token => {
        if (token) {
          return this.http.post(API.permission + '/info', null, { headers: { token } });
        } else {
          return of('');
        }

      }),
    );
  }

	/**
	 * Function, that should perform refresh token verifyTokenRequest
	 * @description Should be successfully completed so interceptor
	 * can execute pending requests or retry original one
	 * @returns {Observable<any>}
	 */
  public refreshToken(): Observable<AccessData> {
    return this.tokenStorage.getRefreshToken().pipe(
      switchMap((refreshToken: string) => {
        return this.http.get<AccessData>(this.API_URL + this.API_ENDPOINT_REFRESH + '?' + this.util.urlParam(refreshToken));
      }),
      tap(this.saveAccessData.bind(this)),
      catchError(err => {
        this.logout();
        return throwError(err);
      })
    );
  }

	/**
	 * Function, checks response of failed request to determine,
	 * whether token be refreshed or not.
	 * @description Essentialy checks status
	 * @param {Response} response
	 * @returns {boolean}
	 */
  public refreshShouldHappen(response: HttpErrorResponse): boolean {
    return response.status === 401;
  }

	/**
	 * Verify that outgoing request is refresh-token,
	 * so interceptor won't intercept this request
	 * @param {string} url
	 * @returns {boolean}
	 */
  public verifyTokenRequest(url: string): boolean {
    return url.endsWith(this.API_ENDPOINT_REFRESH);
  }

	/**
	 * Submit login request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
  public login(credential: Credential): Observable<any> {
    // Expecting response from API
    return this.http.post<AccessData>(`${API.login}`, credential).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
      tap(this.setLanguage.bind(this)),
      tap(this.saveAccessData.bind(this)),
      catchError(this.handleError('login', []))
    );
  }

  public login1(credential: Credential): Observable<any> {
    // Expecting response from API
    return this.http.post(`${API.login}`, credential).pipe(
      map((result: any) => {
        if (result instanceof Array) {
          return result.pop();
        }
        return result;
      }),
    );
  }

	/**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return from(result);
    };
  }

	/**
	 * Logout
	 */
  public logout(refresh?: boolean): void {
    this.tokenStorage.clear();
    if (refresh) {
      location.reload(true);
    }
  }

	/**
	 * Save access data in the storage
	 * @private
	 * @param {AccessData} data
	 */
  private saveAccessData(accessData: AccessData) {
    if ('DATA' in accessData) {
      const { token } = accessData.DATA;
      this.tokenStorage.setAccessToken(token);
      this.onCredentialUpdated$.next(accessData);
    }
  }

  private setLanguage(accessData: AccessData) {
    if ('DATA' in accessData && 'defaultLocale' in accessData.DATA) {
      const localeMap = new Map([
        ['en_US', 'en'],
        ['zh_TW', 'zh-Hant'],
        ['zh_CN', 'zh-Hans'],
      ]);
      const defaultLang = localeMap.get(accessData.DATA.defaultLocale);
      const cachedLang = localStorage.getItem('v6_language');
      this.translationService.setLanguage(defaultLang || cachedLang);
    }
  }

	/**
	 * Submit registration request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
  public register(credential: Credential): Observable<any> {
    // dummy token creation
    credential = Object.assign({}, credential, {
      accessToken: 'access-token-' + Math.random(),
      refreshToken: 'access-token-' + Math.random(),
      roles: ['USER'],
    });
    return this.http.post(this.API_URL + this.API_ENDPOINT_REGISTER, credential)
      .pipe(catchError(this.handleError('register', []))
      );
  }

	/**
	 * Submit forgot password request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
  public requestPassword(credential: Credential): Observable<any> {
    return this.http.get(this.API_URL + this.API_ENDPOINT_LOGIN + '?' + this.util.urlParam(credential))
      .pipe(catchError(this.handleError('forgot-password', []))
      );
  }

  /**
   * Get if user's roles have company level
   * @returns {Observable<any>}
   */

  getUserHasCompanyLevel(): Observable<any> {
    return this.store.pipe(
      select(fromAuth.getAuthToken),
      switchMap(token => {
        if (token) {
          return this.http.get(API.baseUrl + '/roles/checkRoleLevel', { headers: { token } });
        } else {
          return of('');
        }
      }),
    );
  }

  // 获取当前用户的默认组织架构
  getDefaultOrg(): Observable<any> {
    return this.http.get<any>(API.baseUrl + '/corporates/list/table/default/all');
  }
}
