// Angular
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ContentChild, AfterContentInit } from '@angular/core';
// Loading bar
import { LoadingBarService } from '@ngx-loading-bar/core';
// RxJS
import { Observable, BehaviorSubject } from 'rxjs';
// Portlet
import { PortletBodyComponent } from './portlet-body.component';
import { PortletHeaderComponent } from './portlet-header.component';
import { PortletFooterComponent } from './portlet-footer.component';
// Layout
import { LayoutConfigService } from '../../../../../core/_base/layout';

export interface PortletOptions {
	test?: any;
}

@Component({
	selector: 'kt-portlet',
	templateUrl: './portlet.component.html',
	exportAs: 'ktPortlet'
})
export class PortletComponent implements OnInit, AfterViewInit, AfterContentInit {
	// Public properties
	@Input() loading$: Observable<boolean>;
	// portlet extra options
	@Input() options: PortletOptions;
	// portlet root classes
	@Input() class: string;

	@Input() collapsible: boolean;
	isCollapsed$: BehaviorSubject<boolean>;

	@ViewChild('portlet') portlet: ElementRef;

	// portlet header component template
	@ContentChild(PortletHeaderComponent) header: PortletHeaderComponent;
	// portlet body component template
	@ContentChild(PortletBodyComponent) body: PortletBodyComponent;
	// portlet footer component template
	@ContentChild(PortletFooterComponent) footer: PortletFooterComponent;

	/**
	 * Component constructor
	 *
	 * @param el: ElementRef
	 * @param loader: LoadingBarService
	 * @param layoutConfigService: LayoutConfigService
	 */
	constructor(private el: ElementRef, public loader: LoadingBarService,
	            private layoutConfigService: LayoutConfigService) {
		this.loader.complete();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
	}

	/**
	 * After view init
	 */
	ngAfterViewInit() {
	}

	ngAfterContentInit(): void {
		if (this.collapsible && this.header) {
			this.header.collapsible = true;
			if (this.body) {
				this.body.isCollapsed$ = this.header.isCollapsed$;
			}
		}
	}
}
