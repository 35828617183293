export class OrdersTable {
	public static orders: any = [
		// e-commerce => orders
		{
			id: 'a68e31e0-9d88-4065-abc4-c74cc7c293ae',
			customer_id: 38,
			order_date: '8/6/2012',
			items: [
				{
					product_id: 31,
					count: 1
				},
				{
					product_id: 1,
					count: 1
				}
			],
			order_status: 'on the way'
		},
		{
			id: '5055fa9e-3b1a-4668-a63e-4767e9cda711',
			customer_id: 58,
			order_date: '2/13/2012',
			items: [
				{
					product_id: 98,
					count: 2
				},
				{
					product_id: 11,
					count: 1
				}
			],
			order_status: 'on the way'
		},
		{
			id: '0c06643b-030a-4ea0-92e5-e0177645943d',
			customer_id: 13,
			order_date: '4/19/2016',
			items: [
				{
					product_id: 32,
					count: 1
				},
				{
					product_id: 77,
					count: 1
				}
			],
			order_status: 'sent'
		},
		{
			id: '8bbdbe05-88c6-47f2-b531-65fd9f97d649',
			customer_id: 97,
			order_date: '7/30/2012',
			items: [
				{
					product_id: 96,
					count: 1
				},
				{
					product_id: 99,
					count: 2
				}
			],
			order_status: 'delivered'
		},
		{
			id: '2af76e30-f9d6-427c-aad4-414908459e7f',
			customer_id: 90,
			order_date: '12/20/2012',
			items: [
				{
					product_id: 100,
					count: 5
				}
			],
			order_status: 'paid'
		},
		{
			id: '7951e68b-e2e9-4cf9-82c4-9e963031b6b7',
			customer_id: 82,
			order_date: '1/4/2014',
			items: [
				{
					product_id: 35,
					count: 1
				},
				{
					product_id: 88,
					count: 1
				},
				{
					product_id: 22,
					count: 1
				}
			],
			order_status: 'delivered'
		},
		{
			id: '4dc2c379-c7aa-4356-b609-0ca5777b7b8e',
			customer_id: 91,
			order_date: '8/2/2016',
			items: [
				{
					product_id: 36,
					count: 1
				}
			],
			order_status: 'on the way'
		},
		{
			id: '3eaf04e5-855c-4a57-b781-3edff99ddae5',
			customer_id: 63,
			order_date: '4/11/2012',
			items: [
				{
					product_id: 67,
					count: 1
				}
			],
			order_status: 'on the way'
		},
		{
			id: '4cca88ef-1a39-48cf-a6ed-290e7e34f756',
			customer_id: 5,
			order_date: '4/19/2011',
			items: [
				{
					product_id: 9,
					count: 1
				}
			],
			order_status: 'sent'
		},
		{
			id: '3cd8e2b8-83ee-4c0d-a4c3-fed313fc836a',
			customer_id: 81,
			order_date: '10/2/2010',
			items: [
				{
					product_id: 98,
					count: 1
				}
			],
			order_status: 'on the way'
		},
		{
			id: 'da2f7ea8-82aa-474a-a97f-3d75d56d734f',
			customer_id: 100,
			order_date: '3/30/2016',
			items: [
				{
					product_id: 3,
					count: 1
				}
			],
			order_status: 'sent'
		},
		{
			id: 'e674592d-ea7f-40d4-aa2c-ecac4039c81b',
			customer_id: 4,
			order_date: '6/25/2011',
			items: [
				{
					product_id: 71,
					count: 1
				}
			],
			order_status: 'delivered'
		}
	];
}
