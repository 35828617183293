// English
export const locale = {
  lang: 'en',
  data: {
    MENU: {
      TASK_DETAIL: 'Task Detail',
      TASK_CONTENT: 'Task Content',
      DASHBOARD: 'Dashboard',
      DASHBOARD_REP: 'Module Report',
      DASHBOARD_TRA: 'Training Dashboard',
      DASHBOARD_RIS: 'Risk Dashboard',
      DASHBOARD_REG: 'Regulatory Dashboard',
      DASHBOARD_INC: 'Incident Dashboard',
      DASHBOARD_FOOD: 'Food Safety Report',
      DASHBOARD_ASSESSMENT: 'Assessment Questions Analysis',
      DASHBOARD_ISSUE: 'Issue And 5S Analysis',
      DASHBOARD_INSPECTION_CORRECTION: 'Inspection Correction Analysis',
      DASHBOARD_TRAINING: 'Training',
      DASHBOARD_BI_SMALL: 'BI Small Project',
      DASHBOARD_HC_SMALL: 'HC Small Project',
      DASHBOARD_PERFORMANCE: 'Performance Data',
      WHITE_LIST: 'White List',
      FS_KPI: 'FS KPI',
      FMHC_KPI: 'FM-HC KPI',
      FMBI_KPI: 'FM-BI KPI',
      ATP: 'ATP Analyse',
      DASHBOARD_HOMEPAGE: 'Homepage',
      KPI_EXEMPTION: 'KPI Exemption',
      DASHBOARD_CUSTOM_SAT: 'Custom Satisfaction Report',
      SYSTEM_SETUP: 'System Setup',
      TAS110: 'Personal Task Center',
      TAS111: 'View All Tasks',
      TAS112: 'Unclaimed Tasks',
      TRA100: 'Training and License',
      TRA110: 'Training Schedule',
      TRA111: 'Training Calendar',
      TRA112: 'Dept Schedule',
      TRA113: 'Instructor',
      TRA114: 'Class Scheduling',
      TRA115: 'Training Calendar',
      TRA116: 'Exemption Utility',
      TRA117: 'Employee Training Record',
      TRA118: 'Course Record',
      TRA119: 'My Course',
      TRA120: 'Certificate Management',
      TRA121: 'Certificate Inquiry',
      TRA122: 'Certificate Category',
      TRA130: 'Training Record',
      TRA131: 'Unfinished Training',
      TRA132: 'Finished Training',
      TRA133: 'Conference Attendance',
      TRA134: 'My Course',
      TRA135: 'Employee Record',
      TRA140: 'Question Bank Management',
      TRA141: 'Question Set',
      TRA142: 'Quiz Management',
      TRA143: 'Question Management',
      TRA144: 'Course Scheduling',
      TRA150: 'Attestation',
      TRA151: 'Attestation Grade',
      TRA152: 'Attestation Management',
      TRA153: 'Attestation Category',
      RIS100: 'Risk Assessment',
      RIS110: 'Risk Analysis',
      RIS111: 'Risk Matrix',
      RIS112: 'Job Risk inquiry',
      RIS120: 'Risk Control',
      RIS121: 'Planning List',
      RIS130: 'Risk Record',
      RIS131: 'Risk Assessment Record',
      RIS132: 'Correction Action',
      INC100: 'Incident Management',
      INC110: 'Accident Handling',
      INC111: 'Incident Report',
      INC112: 'Incident Inquiry',
      INC113: 'Incident Investigation',
      INC114: 'Corrective Action',
      INC115: 'Working Hours List',
      INC116: 'Working Hours Detail',
      INC117: 'Injured People',
      INC120: 'Occupation Hazard',
      INC121: 'Hazard Record',
      INC122: 'Working Day Record',
      INC123: 'Hazard Report',
      INC124: 'Injury Record Form',
      INC125: 'Message Center',
      GR100: 'Regulatory Compliance',
      GR110: 'Regulatory Database',
      GR111: 'Regulations Overview',
      GR112: 'My Favorite',
      GR113: 'Company Applicable List',
      GR114: 'Upload Regulation Updates',
      GR120: 'Identification and Correction',
      GR121: 'Compliance Assessment',
      GR122: 'Identification Task',
      GR123: 'Corrective Action',
      SS100: 'System Setting',
      SS110: 'Basic Configuration',
      SS111: 'Organization',
      SS112: 'Employee Management',
      SS113: 'Facility Management',
      SS114: 'Employee Group',
      SS115: 'Announcement Management',
      SS116: 'Role Management',
      SS117: 'Post Group',
      SS120: 'Advanced Settings',
      SS121: 'System Code',
      SS122: 'Role Management',
      SS130: 'Form Management',
      SS131: 'Question Bank',
      SS132: 'Forms',
      SS133: 'Corrective Actions',
      SS140: 'Space Management',
      SS141: 'Space Management',
      SS142: 'Space Type Management',
      SS143: 'Space Group Management',
      SS144: 'Department Space Management',
      INS100: 'Inspection',
      INS110: 'Inspection Activity',
      INS111: 'Issue and 5S Reporting',
      INS112: 'Inspection Scheduling',
      INS113: 'Ad-hoc Inspection',
      INS120: 'Inspection Result',
      INS121: 'Inspection Record',
      INS122: 'Issue list',
      INS123: 'Corrective Action',
      INS124: 'Issue Correction',
      INS125: 'Report Record',
      NM100: 'Notification',
      NM111: 'Message Center',
      MEDICAL_SERVICE: 'Medical Service',
      ENV100: 'Environment Service',
      ENV110: 'Record',
      ENV111: 'Space Inspection Record',
      ENV112: 'Job Assessment',
      ENV113: 'Patient Satifaction',
      ENV114: 'Space Scheduling Inspection',
      ENV115: 'Space Scheduling Dashboard',
      ENV116: 'Customer Quality Inspection Record',
      DLV100: 'Sports escort service',
      DLV110: 'Record',
      DLV111: 'Transport escort service inspection inquiry',
      DLV112: 'Job Assessment',
      DLV113: 'Patient Satifaction',
      DLV114: 'Space Scheduling Inspection',
      EQP100: 'Equipment Management',
      EQP110: 'Record',
      EQP111: 'Job Assessment',
      EQP112: 'Equipment Management',
      EQP113: 'Space Inspection Record',
      EQP114: 'Patient Satifaction',
      EQP115: 'Space Scheduling Inspection',
      SQR100: 'Security Service',
      SQR110: 'Record',
      SQR111: 'Job Assessment',
      SQR113: 'Space Inspection Record',
      SQR114: 'Patient Satifaction',
      SQR115: 'Space Scheduling Inspection',
      SQR112: 'Equipment Management',
      SMR116: 'Medical Service Report',
      SMR117: 'Medical Service Training Report',
      SMR118: 'Patient Satisfaction Surveys Summary',
      SMR119: 'Position Evaluation Report',
      QPE100: 'ASE & QPE',
      QPE110: 'QPE Management',
      QPE111: 'QPE Report',
      QPE112: 'QPE Inquiry',
      QPE113: 'Scheduling Inspection',
      QPE114: 'QPE Corrective Action List',
      QPE120: 'Project Assessment',
      QPE121: 'Project Assessment Inquiry',
      QPE122: 'Project Assessment Corrective Actions',
      QPE130: 'ASE Management',
      QPE131: 'ASE Report',
      QPE132: 'ASE Inquiry',
      QPE133: 'ASE Corrective Query',
      QPE134: 'ASE Upload Materials',
      DS100: 'Doctor Satisfaction',
      DS110: 'Doctor Satisfaction Management',
      DS111: 'Doctor Satisfaction Inquiry',
      DS112: 'QR Code',
      DOC100: 'Documentation Management',
      DOC110: 'Documentation Management',
      DOC111: 'Documentation Management',
      DOC112: 'Documentation List',
      DOC113: 'Documentation Classification',
      DOC114: 'Project Report',
      SCS110: 'EHS Score',
      SCS111: 'Rules of Points',
      SCS112: 'Score Query',
    },
    GENERAL: {
      ACTIONS: {
        CHANGE_PASSWORD:'Change Password',
        LOGOUT: 'Logout',
        OK: 'OK',
        CLEAR: 'Clear',
        CANCEL: 'Cancel',
        CLOSE: 'Close',
        LEAVE: 'Leave',
        SUBMIT: 'Submit',
        RESET: 'Reset',
        SEARCH: 'Search',
        VIEW: 'View',
        ADD: 'Add',
        EDIT: 'Edit',
        EXPORT: 'Export',
        EXPORT_ALL: 'Export All',
        DELETE: 'Delete',
        UPDATE: 'Update',
        SAVE: 'Save',
        SAVE_SUBMIT: 'Save & Submit',
        DO_NOT_SAVE: 'Do Not Save',
        SAVE_CONFIRM: 'Save and Confirm',
        SAVE_LEAVE: 'Save and Leave',
        BACK: 'Back',
        PREVIOUS: 'Previous',
        NEXT: 'Next',
        NO: 'No',
        YES: 'Yes',
        CHOOSE: 'Choose',
        CLEAR_SELECTION: 'None',
        BACK_TO_LIST: 'Back To List',
        PUBLISH: 'Publish',
        ADD_NEW_VERSION: 'Add New Version',
        ADVANCED_SEARCH_SHOW: 'Show Advanced Search',
        ADVANCED_SEARCH_HIDE: 'Hide Advanced Search',
        SUBMIT_PREVIEW: 'Submit Preview',
        ASSIGN: 'Assign',
        CLAIM: 'Claim',
        EXPORT_EXCEL: 'Export Excel',
        EXPORT_PDF: 'Export Pdf',
        DRAFT: 'Save As Draft',
        CLOSE_CASE: 'Close Case',
        PRINT: 'Print',
        REVOKE: 'Revoke',
        REJECT: 'Reject',
        SHUTDOWN: 'Shutdown',
        END: 'End',
        CALCULATE: 'Review'
      },
      FORM_REGION: 'Region',
      FORM_SECTION: 'Section',
      NO_ATTACHMENTS: 'Attachment(s) is required!',
      POSITIVE: 'Yes',
      NEGATIVE: 'No',
      TITLE: {
        DELETE: 'Delete',
        LEAVE: 'Leave site?',
        SURE: 'Clear report?'
      },
      MSG: {
        DELETE: 'Record(s) will be deleted and cannot undo. Are you sure to continue?',
        CONFIRM_LEAVE: 'There might be unsaved content. Are you sure to leave?',
        LEAVE: 'Changes you made may not be saved.',
        SURE: 'Are you sure to clear the report?',
        UNFILLED_FIELD: 'Unable to save, there are unfilled fields.',
        UNFILLED_FIELD_SUBMIT: 'Unable to submit, there are unfilled fields.',
        FIELD_EMPTY: 'Please filled out all necessary fileds',
        CREATE_SUCCESS: 'Field/Option created',
        UPDATE_SUCCESS: 'Field/Option saved',
        DELETE_SUCCESS: 'Field/Option delete',
        SAVE_SUCCESS: 'Save Success',
        SUBMIT_SUCCESS: 'Submit Success',
        PUBLISH_SUCCESS: 'Publish Success',
        REVOKE_SUCCESS: 'Revoke Success',
        CLOSE_SUCCESS: 'Close Success',
      },
      NONE: 'None',
      NO_RESULT: 'No result',
      PLACEHOLDER: {
        SEARCH: 'Enter to search',
        SELECT: 'Choose an option',
        CLARIFY: 'Please explain',
        INPUT: 'Please input'
      },
      SELECT_REQUIRED: 'Please select an item',
    },
    LABEL: {
      CHECKBOX_SELECTED: 'selected'
    },
    DX: {
      NO_DATA_TEXT: 'No data',
      NO_DATA_TEXT_MEDICAL:'No overdue outstanding data',
      NO_DATA_TEXT_EMP:'No employee data to be trained',
      PAGER: {
        INFOTEXT: 'Page {0} of {1} ({2} items)'
      },
      UPLOADER: {
        SELECT_BTN_TEXT: 'Select File',
        LABEL_TEXT: 'or Drop file here',
        READY_MSG: 'Ready to upload',
        MAX_FILE_SIZE: 'Maximum file size',
        INVALID_MAX_FILE_SIZE_MSG: 'File is too large',
        IGNORE_OVERLARGE_FILE_HINT: 'Overlarge files won\'t be uploaded',
        ONLY_PIC: 'Only image files can be uploaded',
        RESET: 'Clear Selected File',
        DELETE: 'Delete File',
        CONFIRM_DELETE: 'Are you sure to delete this file?',
        FAILED_TO_UPLOAD: 'Failed to upload',
        IMPORT: 'Import',
        PICTURE_HINT: 'Please upload picture file with suffix of jpg, jpeg, gif or png. Picture file size under 2M is recommended.',
        FILE_INVALID: 'Selected file is not allowed. Plesase select attachments correctly.',
        INVALID_FILE_EXTENSION_MSG: 'File extension is not allowed',
      }
    },
    MAT: {
      PAGINATOR: {
        ITEMS_PER_PAGE: 'Items per page',
        FIRST: 'First page',
        LAST: 'Last page',
        PREVIOUS: 'Previous page',
        NEXT: 'Next page',
      },
    },
    HTTP_ERROR_MSG: {
      '401': 'Authentication failed. Please login',
      '404': 'System is offline or under maintainence. Please try again later',
      '413': 'Uploaded file was too large. Please try smaller file',
      '504': 'Exported data cannot be returned right now due to amount of data being exported. Once export is complete, you\'ll receive a notification in message center. Please wait.'
    }
  }
};
