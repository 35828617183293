import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import * as fromAuth from '@auth/reducers';
import * as AuthActions from '@auth/actions/auth';

@Injectable()
export class InterceptService implements HttpInterceptor {

  constructor(
    private router: Router,
    private store: Store<fromAuth.State>,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
  ) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // 大屏报表token过期会导致页面回到登录页
    // if (location.href.indexOf('/screen-dashboard/') !== -1) {
      // localStorage.removeItem('token');
    // }

    if (this.isAtLoginPage()) {
      return next.handle(request);
    }
    let modifiedRequest;
    if (request.urlWithParams.indexOf('adHocInspection/share') !== -1 || request.urlWithParams.indexOf('projectLargeScreenReport') !== -1) {
      modifiedRequest = request.clone({
        setHeaders: {}
      });
    } else {
      const token = localStorage.getItem('token');
      const currentLocale = localStorage.getItem('v6_language') || 'en';
      modifiedRequest = request.clone({
        setHeaders: {
          token,
          currentLocale  // temp regulation
        }
      });
    }
    
    return next.handle(modifiedRequest).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            // console.log(event.status);
            // console.log('event body', event.body);
          }
        },
        error => {
          this.errorHandler(error, request);
        },
      )
    );
  }

  private errorHandler(error, request: HttpRequest<any>) {
    // console.error(error.status);
    // console.error('error message', error.message);
    let msg: string;
    switch (error.status) {
      case 401:
        // console.log('401处', window.location.href)
        if (window.location.href.indexOf('/ehs/qpe/qpe-query-list') !== -1 && window.location.href.indexOf('inspectionId=') !== -1 && window.location.href.indexOf('taskRefId=') !== -1) {

        } else {
          msg = this.translateService.instant('HTTP_ERROR_MSG.401');
        }
        // msg = this.translateService.instant('HTTP_ERROR_MSG.401');
        if (!this.isAtLoginPage()) {
          sessionStorage.setItem('savedUrl', this.router.url);
        }
        this.clearAuthState();
        break;
      case 404:
        msg = this.translateService.instant('HTTP_ERROR_MSG.404');
        if (!this.isAtLoginPage()) {
          sessionStorage.setItem('savedUrl', this.router.url);
        }
        this.clearAuthState();
        break;
      case 413:
        msg = this.translateService.instant('HTTP_ERROR_MSG.413');
        break;
      case 504:
        if (request.url.indexOf('export') !== -1) {
          msg = this.translateService.instant('HTTP_ERROR_MSG.504');
        } else {
          msg = error.message;
        }
        break;
      default:
        msg = error.message;
        break;
    }
    if (msg) {
      this.snackBar.open(msg, '', { duration: 5000 });
    }
  }

  private isAtLoginPage(): boolean {
    return this.router.url === '/auth/login';
  }

  private clearAuthState() {
    this.store.dispatch(AuthActions.Logout());
  }
}
