import { environment, baseURI } from '@environments/environment';

const baseUrl = environment.baseUrl;

const api = {
  /** === AUTH === */
  login: baseUrl + '/permissions/login',
  permission: baseUrl + '/permissions',
  /** === SYSTEM === */
  facility: baseUrl + '/facilities',
  location: baseUrl + '/locations',
  code: baseUrl + '/codes',
  corporate: baseUrl + '/corporates',
  user: baseUrl + '/users',
  employee: baseUrl + '/employees',
  file: baseUrl + '/file',
  messageGroup: baseUrl + '/messageGroup',
  postGroup: baseUrl + '/group',
  message: baseUrl + '/message',
  projectReport: baseUrl + '/projectReport',
  /** === SAFETY OBSERVATION === */
  observation: baseUrl + '/observation',
  /** === REGULATION === */
  govRegulation: baseUrl + '/govRegulation',
  govRegulationExplanation: baseUrl + '/govRegulationExplanation',
  govRegulationItem: baseUrl + '/govRegulationItem',
  govRegulationMasterOrg: baseUrl + '/govRegulationMasterOrg',
  govRegulationMasterUser: baseUrl + '/govRegulationMasterUser',
  identRegulationActivity: baseUrl + '/identRegulationActivity',
  /** === TRAINING === */
  training: baseUrl + '/training',
  /** === RISK === */
  riskAssessment: baseUrl + '/riskAssessment',
  activities: baseUrl + '/activities',
  taskCenter: baseUrl + '/tasks/taskCenter',
  taskCenterPersonalToDo: baseUrl + '/tasks/taskCenterPersonal',
  taskCenterPersonalHistory: baseUrl + '/tasks/taskCenterPersonalHistory',
  taskBpm: baseUrl + '/taskBpm',
  tasks: baseUrl + '/tasks',
  correctiveAction: baseUrl + '/tasks/correctiveAction',
  /** === INCIDENT === */
  incident: baseUrl + '/incident',
  orgContact: baseUrl + '/orgContact/list',
  incidentInvolved: baseUrl + '/incidentInvolved/list',
  incidentDiagnostic: baseUrl + '/incidentDiagnostic',
  incidentTasks: baseUrl + '/incidentTasks',
  incidentInvestigationDetailInfo: baseUrl + '/incidentInvestigationDetailInfo',
  incidentAuditAnalysis: baseUrl + '/incidentAuditAnalysis',
  incidentLossPersonnel: baseUrl + '/incidentLossPersonnel',
  incidentAuditPersonnelLoss: baseUrl + '/incidentAuditPersonnelLoss',
  incidentByCorrectiveAction: baseUrl + '/incidents/getByCorrectiveTaskId',
  workingHours: baseUrl + '/workHour',
  injuredPeople: baseUrl + '/incidentInvolved',
  // 需要更改
  incidentInquiry: baseUrl + '/incidentInquiry',
  abnormalEvent: baseUrl + '/abnormalEvent',
  incidentInvestigation: baseUrl + '/incidentInvestigation',
  incidentPersonalReportDetailedInfo: baseUrl + '/incidentPersonalReportDetailedInfo',
  personalReportBasicInfo: baseUrl + '/personalReportBasicInfo',
  incidentPersonalReportCauseAnalisys: baseUrl + '/incidentPersonalReportCauseAnalisys',
  incidentTaskContent: baseUrl + '/incidentTaskContent',
  baseUrl: baseUrl,
  hazard: baseUrl + '/hazard',
  /** === System Setting === */
  organization: baseUrl + '/corporates',
  formManagement: baseUrl + '/forms',
  announcement: baseUrl + '/announcement',
  /** === INSPECTION * === */
  inspection: baseUrl + '/inspections',
  deviations: baseUrl + '/deviations',
  inspectionTasks: baseUrl + '/inspectionTasks',
  /** === ROLES * === */
  roles: baseUrl + '/roles',
  menus: baseUrl + '/menus',
  /** === Charts * === */
  incidentChart: baseUrl + '/incidentChart',
  kpiChart: baseUrl + '/kpiChart',
  dashboardChart: baseUrl + '/dashboardChart',
  aliyun: baseUrl + '/aliyun',
  msgGroup: baseUrl + '/messageGroup',
  spaceChart: baseUrl + '/spaceChart',
  /** === COMPLIANT DOCUMENT */
  compliantDocumentCategory: baseUrl + '/compliantDocumentCategory',
  compliantDocument: baseUrl + '/compliantDocument',
  // APE与项目自评
  aseInspection: baseUrl + '/aseInspection'
};

export const API = api;
