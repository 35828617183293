// Traditional Chinese
export const locale = {
  lang: 'zh-Hant',
	data: {
    AUTH: {
			GENERAL: {
				OR: '或',
				SUBMIT_BUTTON: '送出',
				NO_ACCOUNT: '沒有帳號嗎？',
				SIGNUP_BUTTON: '註冊',
				FORGOT_BUTTON: '忘記密碼',
				BACK_BUTTON: '返回',
				PRIVACY: '隱私權政策',
				LEGAL: '法律',
				CONTACT: '聯絡我們',
			},
			LOGIN: {
				TITLE: '登入帳號',
				BUTTON: '登入',
			},
			FORGOT: {
				TITLE: '忘記密碼？',
				DESC: '輸入電子信箱以重設您的密碼',
			},
			REGISTER: {
				TITLE: '註冊',
				DESC: '輸入資料以註冊一個新帳號',
				SUCCESS: '您已成功註冊。請使用此帳號登入'
			},
			INPUT: {
				USER_NAME: '使用者名稱',
				EMAIL: '電子信箱',
				FULLNAME: '使用者全名',
				PASSWORD: '密碼',
				CONFIRM_PASSWORD: '確認密碼',
			},
			VALIDATION: {
				INVALID: '{{name}}不正確',
				REQUIRED: '{{name}}為必填',
				MIN_LENGTH: '{{name}}的最小長度為 {{min}}',
				AGREEMENT_REQUIRED: '需同意使用者條款',
				NOT_FOUND: '無此{{name}}',
				INVALID_LOGIN: '登入資訊錯誤'
			}
		}
  }
};
