import {
  createSelector,
  createFeatureSelector,
} from '@ngrx/store';
import * as fromRoot from '@_core/reducers';

import * as fromAuth from './auth';

export interface State extends fromRoot.State {
  auth: fromAuth.State;
}

export const reducers = fromAuth.reducer;

// feature selector
export const selectAuthState = createFeatureSelector<State, fromAuth.State>('auth');

// auth
export const getAuthToken = createSelector(
  selectAuthState,
  state => state.token
);
