// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// rxjs
import { Observable } from 'rxjs';

// Config
import { API } from '@quantum-theme/core/_config/api/api';

// Models
import { ResponseModel } from '@_core/models/response';
import { QueryUtilsModel } from '@_core/models/logic-custom-query/query-utils.model';
import { QueryCriteria } from '@_core/models/logic-custom-query/query-criteria.model';

const formManagementAPI = API.formManagement;

@Injectable()
export class FormManagementService {
  constructor(
    private http: HttpClient
  ) { }

  getFormPreview(id: string): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(formManagementAPI + '/' + id + '/preview');
  }

  queryFormList(query: string, inspectionType?, checkType?): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 1000
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    if (checkType) {
      queryUtils['params'] = {
        checkType: "'" + checkType + "'"
      };
    }

    queryUtils.queryCriterias = [
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      // ...(query ? [new QueryCriteria('formName', query, 'like')] : []),
    ];
    if (inspectionType) {
      queryUtils.queryCriterias.push(...(inspectionType ? [new QueryCriteria('inspectionType', inspectionType, '=')] : []));
    }

    return this.http.post<ResponseModel>(formManagementAPI + '/list', queryUtils);
  }


  queryPreviewableFormList(query: string, inspectionType?: string, selectedOrgId?: string): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 35
      },
      queryOrderBies: [
        {
          columnName: 'id',
          orderType: 'desc'
        }
      ]
    };
    queryUtils.queryCriterias = [
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      // ...(query ? [new QueryCriteria('formName', query, 'like')] : []),
    ];
    if (inspectionType) {
      queryUtils.queryCriterias.push(...(inspectionType ? [new QueryCriteria('inspectionType', inspectionType, '=')] : []),)
    }
    if (selectedOrgId) {
      queryUtils.queryCriterias.push(...(selectedOrgId ? [new QueryCriteria('org_id', selectedOrgId, '=')] : []),)
    }
    return this.http.post<ResponseModel>(formManagementAPI + '/havingPreview/list', queryUtils);
  }

  queryExcludeMedical(query: string): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 35
      },
      queryOrderBies: [
        {
          columnName: 'id',
          orderType: 'desc'
        }
      ]
    };
    queryUtils.queryCriterias = [
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      // ...(query ? [new QueryCriteria('formName', query, 'like')] : []),
      ...([new QueryCriteria('serviceType', '_SYS_WH_14_1', '=')])
    ];
    return this.http.post<ResponseModel>(formManagementAPI + '/havingPreview/list', queryUtils);
  }

  queryPreviewableFormListScheduling(query: string, inspectionType?: string, selectedOrgId?: string, serviceType?: string): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 35
      },
      queryOrderBies: [
        {
          columnName: 'id',
          orderType: 'desc'
        }
      ]
    };
    queryUtils.queryCriterias = [
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      // ...(query ? [new QueryCriteria('formName', query, 'like')] : []),
    ];
    if (inspectionType) {
      queryUtils.queryCriterias.push(...(inspectionType ? [new QueryCriteria('inspectionType', inspectionType, '=')] : []));
    }
    if (selectedOrgId) {
      queryUtils.queryCriterias.push(...(selectedOrgId ? [new QueryCriteria('org_id', selectedOrgId, '=')] : []));
    }
    if (serviceType) {
      queryUtils.queryCriterias.push(...(serviceType ? [new QueryCriteria('serviceType', serviceType, '=')] : []));
    }
    return this.http.post<ResponseModel>(formManagementAPI + '/havingPreview/list/plan', queryUtils);
  }
}
