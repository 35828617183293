import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthNoticeService } from '../../services/auth-notice.service';
import { NgForm } from '@angular/forms';
import * as objectPath from 'object-path';
import { TranslateService } from '@ngx-translate/core';
// import { SpinnerButtonOptions } from '../../../partials/content/general/spinner-button/button-options.interface';

import { Store } from '@ngrx/store';
import * as AuthActions from '../../actions/auth';
import * as fromAuth from '../../reducers';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'quantum-login',
  templateUrl: './login.html',
  styleUrls: ['./login.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginPage implements OnInit, OnDestroy {
  public model: any = { userName: '', password: '', channelType: '_SYS_LT_B' };
  public loading = false;

  @ViewChild('f') f: NgForm;
  errors: any[] = [];

  actionsSub: Subscription;

  constructor(
    public authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private store: Store<fromAuth.State>,
    private actions$: Actions,
  ) {
    this.actionsSub = this.actions$.pipe(
      ofType(AuthActions.LoginFailure)
    ).subscribe(() => {
      this.loading = false;
      this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'error');
    });
  }

  submit() {
    if (this.validate(this.f)) {
      this.loading = true;
      this.store.dispatch(AuthActions.Login({ credential: this.model }));
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.authNoticeService.setNotice(null);
    this.actionsSub.unsubscribe();
  }

  validate(f: NgForm) {
    if (f.form.status === 'VALID') {
      return true;
    }

    this.errors = [];

    if (objectPath.get(f, 'form.controls.userName.errors.required')) {
      this.errors.push(this.translate.instant('AUTH.VALIDATION.REQUIRED', { name: this.translate.instant('AUTH.INPUT.USER_NAME')}));
    }
    if (objectPath.get(f, 'form.controls.password.errors.required')) {
      this.errors.push(this.translate.instant('AUTH.VALIDATION.REQUIRED', {name: this.translate.instant('AUTH.INPUT.PASSWORD')}));
    }
    if (objectPath.get(f, 'form.controls.password.errors.minlength')) {
      this.errors.push(this.translate.instant('AUTH.VALIDATION.MIN_LENGTH', {name: this.translate.instant('AUTH.INPUT.PASSWORD'), min: 4}));
    }

    if (this.errors.length > 0) {
      this.authNoticeService.setNotice(this.errors.join('<br/>'), 'error');
    }

    return false;
  }
}
