// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {AuthGuard} from '@quantum-theme/core/guards/auth.guard';

const routes: Routes = [
	{path: '', redirectTo: 'quantum', pathMatch: 'full'},
  {path: 'auto-login', loadChildren: 'app/auto-login/auto-login.module#AutoLoginModule'},
  {path: 'callback', loadChildren: 'app/callback/callback.module#CallbackModule'},
  {path: 'auth', loadChildren: 'app/auth/auth.module#AuthModule'},
  {
    path: 'quantum',
    canActivate: [AuthGuard],
    loadChildren: 'app/quantum-theme/views/themes/quantum/theme.module#ThemeModule'
  },
  {path: 'share', loadChildren: 'app/share-inspection/share-inspection.module#ShareInspectionModule'},
  {path: 'share-space-table', loadChildren: 'app/share-tables/share-tables.module#ShareTableModule'},
  {path: 'ase-email-detail', canActivate: [AuthGuard], loadChildren: 'app/ase-email-detail/ase-email-detail.module#AseEmailDetaiModule'},
  {path: 'share-notice', loadChildren: 'app/share-accident-notice/share-accident-notice.module#ShareAccidentNoticeModule'},
  // {path: 'screen-dashboard', loadChildren: 'app/screen-dashboard/screen-dashboard.module#LargeScreenDashboardModule'},
  {path: 'screen-dashboard', loadChildren: 'app/screen/screen.module#ScreenDashboardModule'},
	{path: '**', redirectTo: 'quantum/error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
