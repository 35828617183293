// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// export const environment = {
//   production: false,
//   isMockEnabled: true, // You have to switch this, when your real back-end is done (used to be true)
//   authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
//   disableRealAPI: false,
//   // baseUrl: 'http://evaair-dev.logicsolutions.com.tw/facts_backend-2.6/rest', // old site
//   // ehsOrgId: 35,
//   // baseUrl: 'http://aramark-alpha.usequantum.com.cn/facts_backend-2.6/rest', // dev site
//   baseUrl: 'http://aramark-dev.usequantum.com.cn/facts_backend-2.6/rest',
//   // baseUrl: 'http://192.168.1.131/facts_backend-2.6/rest',
//   // baseUrl: 'http://feature-qa.logicsolutions.com.tw//facts_backend-2.6/rest', // dev site
//   // baseUrl: 'http://feature-qa.logicsolutions.com.tw/facts_backend-2.6/rest', // qa site
//   // baseUrl: 'http://quantum-dev.logicsolutions.com.tw/facts_backend-2.6/rest', // dev site
//   ehsOrgId: 51,
//   infirmaryId: 150,
// };

const DEPLOY = document.domain.toLowerCase().indexOf('localhost') !== -1 || document.domain.toLowerCase().indexOf('192.168') !== -1 ? 'dev' : 'alpha';
// const DEPLOY = document.domain.toLowerCase().indexOf('192.168.0.54') !== -1 ? 'dev' : 'alpha';
const str = document.baseURI;

const FETCH = {
  'alpha': {
    production: false,
    isMockEnabled: false, // You have to switch this, when your real back-end is done
    authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
    disableRealAPI: false,
    baseUrl: str + 'facts_backend-2.6/rest',
    ehsOrgId: 35,
    infirmaryId: 400,
  },
  'dev': {
    production: false,
    isMockEnabled: false, // You have to switch this, when your real back-end is done
    authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
    disableRealAPI: false,
    baseUrl: 'https://aramark-alpha.usequantum.com.cn/facts_backend-2.6/rest',
    // baseUrl: 'https://ehs.aramark.cn/facts_backend-2.6/rest',
    // baseUrl: 'https://aramark-stage.usequantum.com.cn/facts_backend-2.6/rest',
    ehsOrgId: 35,
    infirmaryId: 400,
  },
  // 'stag': {
  //   API_DOMAIN: 'http://sinotrans-staging.yunchuanlu.com',
  //   API_DIR: '/facts_backend-2.6/rest',
  //   UPLOAD_URL: 'http://sinotrans-staging.yunchuanlu.com',
  //   IOS_VERSION: '1.1.7',
  //   ANDROID_VERSION: '1.1.7',
  // }
};

export const environment = FETCH[DEPLOY];
export const baseURI = document.baseURI;

