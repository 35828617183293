// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// NgBootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// Partials
import { PartialsModule } from '@metronic/views/partials/partials.module';
// Pages
import { CoreModule } from '@metronic/core/core.module';
import { InterceptService } from '@_core/services/intercept.service';
// import { MailModule } from '@metronic/views/pages/apps/mail/mail.module';
// import { ECommerceModule } from '@metronic/views/pages/apps/e-commerce/e-commerce.module';
// import { UserManagementModule } from '@metronic/views/pages/user-management/user-management.module';
// import { MyPageComponent } from '@metronic/views/pages/my-page/my-page.component';


@NgModule({
  declarations: [
    // MyPageComponent
  ],
  exports: [],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    CoreModule,
    PartialsModule,
    // MailModule,
    // ECommerceModule,
    // UserManagementModule,
  ],
  providers: [
    // {
		// 	provide: HTTP_INTERCEPTORS,
		// 	useClass: InterceptService,
		// 	multi: false
		// }
  ]
})
export class PagesModule {
}
