import { Injectable, ChangeDetectorRef } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription, of, Subject, Subscriber } from 'rxjs';
import { map, tap, combineLatest, skipWhile, finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import * as fromAuth from '@auth/reducers';
import * as AuthActions from '@auth/actions/auth';
import { AuthenticationService } from '@auth/services/authentication.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthNoticeService, AuthService, Login } from '../../../metronic/core/auth';
// Translate
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class AuthGuard implements CanActivate {
  auth$: Observable<any>;
  authSubscription: Subscription;
  permissionsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  loading = false;

  private unsubscribe: Subject<any>;
  constructor(
    private store: Store<fromAuth.State>,
    private router: Router,
    private authService: AuthenticationService,
    private permissionsService: NgxPermissionsService,
  ) {
    this.auth$ = this.store.pipe(select(fromAuth.selectAuthState));
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    // return of(true); // temp regulation
    return this.auth$.pipe(
      map(state => state.loggedIn),
      tap(loggedIn => {
        if (!loggedIn) {
          const a = window.location.href.toString();
          if (a.indexOf('un=') !== -1 && a.indexOf('&tk') !== -1) {
            const unIndex = a.indexOf('un=');
            const tkIndex = a.indexOf('&tk');
            // const tokenIndex = a.indexOf('&token');
            const userName = a.slice(unIndex + 3, tkIndex);
            const password = a.slice(tkIndex + 4, a.length);
            // const token = a.slice(tokenIndex + 7, a.length - 1);

            const urlStart = a.indexOf('/quantum/ehs');
            let urlEnd;
            let url;
            if(urlStart !== -1){
              urlEnd = a.indexOf('&un=') !== -1 ? a.indexOf('&un=') : a.indexOf('?un=');
              url = a.slice(urlStart, urlEnd);
            }else{
              // ase邮件链接查询页面
              urlEnd = a.indexOf('&un=') !== -1 ? a.indexOf('&un=') : a.indexOf('?un=');
              url = a.slice(a.indexOf('/ase-email-detail'), urlEnd)
            }
            // const urlEnd = a.indexOf('&un=') !== -1 ? a.indexOf('&un=') : a.indexOf('?un=');
            // const url = a.slice(urlStart, urlEnd);

            localStorage.setItem('token', password);

            const data = {
              userName: userName,
              password: password,
              channelType: '_SYS_LT_B'
            };

            this.authService.login1(data).subscribe(res => {
              localStorage.setItem('token', res.DATA.token);
                this.router.navigateByUrl(url);
            });

          } else {
            const url = window.location.pathname;
            if (url) {
              sessionStorage.setItem('savedUrl', url);
            }
            this.router.navigateByUrl('/auth/login');
          }

          // this.router.navigateByUrl('/auth/login');
        }
      }),
      combineLatest(
        this.loadPermissions(),
        this.loadAccessModules(),
        (loggedIn, userRoles, accessModules) => {
          if (loggedIn) {
            this.permissionsService.loadPermissions(userRoles.concat(accessModules));
            return true;
          }
          return false;
        })
    );
  }

  private loadPermissions() {
    this.authService.getAccessToken()
      .subscribe((token) => {
        if (token) {
          this.store.dispatch(AuthActions.LoginWithSavedToken({ token }));
        }
      });
    return this.authService.getUserRoles()
      .pipe(
        skipWhile(state => state.length <= 0)
      );
  }

  private loadAccessModules() {
    return this.authService.getAccessModules()
      .pipe(
        skipWhile(state => state.length <= 0)
      );
  }
}
