// Angular
import { Component, HostBinding, Input, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
	selector: 'kt-portlet-body',
	template: `
		<ng-content></ng-content>`,
	styleUrls: ['./portlet-body.component.scss']
})
export class PortletBodyComponent implements OnInit, AfterViewInit, OnDestroy {
	// Public properties
	@HostBinding('class') classList = 'kt-portlet__body';
	@Input() class: string;
	isCollapsed$: BehaviorSubject<boolean>;
	collapseSub: Subscription;
	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		if (this.class) {
			this.classList += ' ' + this.class;
		}
	}

	ngAfterViewInit(): void {
		if (this.isCollapsed$) {
			this.collapseSub = this.isCollapsed$.subscribe(value => {
				this.classList = 'kt-portlet__body';
				if (value) {
					this.classList += ' ' + 'collapsed';
				}
				if (this.class) {
					this.classList += ' ' + this.class;
				}
			});
		}
	}

	ngOnDestroy(): void {
		if (this.collapseSub) {
			this.collapseSub.unsubscribe();
		}
	}
}
