import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '@quantum-theme/core/_config/api/api';
import { Observable, of } from 'rxjs';
import { ResponseModel } from '@_core/models/response';
import { QueryUtilsModel } from '@_core/models/logic-custom-query/query-utils.model';
import { QueryCriteria } from '@_core/models/logic-custom-query/query-criteria.model';
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

const baseUrlAPI = API.baseUrl;
const lessonAPI = API.training + '/lessons';
const categoryAPI = API.training + '/certifications/categories';

@Injectable()
export class LessonService {
  constructor(
    private http: HttpClient,
  ) { }

  getQuestionSetList(query?, maintenanceDepartment?): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils();
    queryUtils.queryCriterias = [
      ...(query ? [new QueryCriteria('name', query, 'like', 'and')] : []),
      ...(maintenanceDepartment ? [new QueryCriteria('maintenance_department', maintenanceDepartment, '=', 'and')] : []),
    ];
    return this.http.post<ResponseModel>(baseUrlAPI + '/testSet/list', queryUtils);
  }

  getQuizList(query?, name?): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils();
    queryUtils.queryCriterias = [
      {
        "connection": "and",
        "key": "status",
        "condition": "=",
        "isValueADigital": false,
        "value": "_SYS_EL_3_2"
      },
      ...(query ? [new QueryCriteria('name', query, 'like', 'and')] : []),
    ];
    if (!name) {
      queryUtils.queryOrderBies = [{
        "columnName": "-paper_serial_number",
        "orderType": "desc"
      }
      ]
    }

    return this.http.post<ResponseModel>(baseUrlAPI + '/testPaper/list', queryUtils);
  }

  private genQueryUtils(): QueryUtilsModel {
    return {
      pagingTool: {
        currentPage: 1,
        pageSize: 9999,
      },
    };
  }

  getCourseList(query?, selectedOrg?, lessonSource?, courseType?, orgIds?): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils();
    queryUtils.queryCriterias = [
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...([new QueryCriteria('lesson.status', '_SYS_EL_3_2', '=')]),
      // ...(selectedOrg ? [new QueryCriteria('orgId', selectedOrg, 'in')] : []),
      ...(lessonSource ? [new QueryCriteria('source', lessonSource, 'in')] : [])
    ];

    // orgIds 会议签到页课程下拉，与组织架构挂钩，往上找课程

    if (!orgIds) {
      queryUtils.queryCriterias.push(
        ...(selectedOrg ? [new QueryCriteria('orgId', selectedOrg, 'in')] : []),
      )

    }
    if (courseType && courseType.length > 0) {
      // let tempCourseType = "";
      // courseType.forEach(type => {
      //   tempCourseType += "'" + type + "',";
      // });
      // tempCourseType = tempCourseType.slice(0, tempCourseType.length - 1);
      // console.log("courseType:", tempCourseType);
      // queryUtils['params'] = {
      //   type: tempCourseType
      // }

      // queryUtils['params'] = {
      //   type: courseType.join(',')
      // }

      if (orgIds) {
        queryUtils['params'] = {
          type: courseType.join(','),
          orgIds: orgIds,
        }
      } else {
        queryUtils['params'] = {
          type: courseType.join(',')
        }
      }

    }
    return this.http.post<ResponseModel>(lessonAPI + '/downList', queryUtils);
  }

  getCertificateList(query?, showOnlyECertificate?): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils();
    queryUtils.queryCriterias = [
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(showOnlyECertificate ? [new QueryCriteria('e_certificate', '1', '=', 'and')] : []),
    ];
    return this.http.post<ResponseModel>(categoryAPI + '/list', queryUtils);
  }

  getAttestationCategoryList(query?): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils();
    queryUtils.queryCriterias = [
      ...(query ? [new QueryCriteria('category', query, 'like', 'and')] : []),
    ];
    return this.http.post<ResponseModel>(baseUrlAPI + '/ecCategory/list', queryUtils);
  }

}
